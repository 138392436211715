import { FC, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";
import { ButtonToTop } from "./button-to-top.component";
import { Trans } from "react-i18next";

interface Props extends PropsWithChildren {
    className?: string;
    classNameHeader?: string;
    header: string;
    arrow?: boolean;
}

export const InfoWrapper: FC<Props> = ({
    className,
    classNameHeader,
    header,
    children,
    arrow = false
}) => {
    return (
        <main
            className={twMerge(
                "w-full flex flex-col justify-start items-center lg:px-4 lg:pb-0",
                className
            )}>
            <div className="max-w-360 px-4 w-full">
                <div className="bg-header bg-center bg-no-repeat bg-cover rounded-10">
                    <h1
                        className={twMerge(
                            "bg-gradient-to-b from-athens-gray to-manatee bg-clip-text text-transparent py-24 text-6xl text-center font-medium mb-8 lg:text-3xl lg:py-16 ",
                            classNameHeader
                        )}>
                        <Trans i18nKey={header} />
                    </h1>
                </div>

                <section aria-label={header} className="relative w-full flex justify-center">
                    {children}
                </section>
                {arrow && <ButtonToTop />}
            </div>
        </main>
    );
};
