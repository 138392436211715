import { FC, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { supportLink } from "constants/index";
import { scrollToTop } from "utils";
import { InfoWrapper } from "components/common/info-wrapper.component";

export const Refund: FC = () => {
    const { t } = useTranslation();
    const defaultStyles = "text-base font-normal leading-6";
    const titleStyles = "text-xl font-semibold text-baltic-sea mb-3";

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <InfoWrapper header="refund.header" arrow={true}>
            <section className={twMerge(defaultStyles, "mt-3 w-9/12 lg:w-full")}>
                <p className={twMerge(defaultStyles, "mb-6")}>{t("refund.purchase")}</p>
                <h2 className={titleStyles}>{t("refund.refunds")}</h2>
                <p className={twMerge(defaultStyles, "mb-4")}>
                    <Trans
                        i18nKey="refund.note"
                        components={{
                            "support-link": <span>{supportLink}</span>
                        }}
                    />
                </p>
                <p className={twMerge(defaultStyles, "mb-6")}>{t("refund.request")}</p>
                <h2 className={titleStyles}>{t("refund.returns")}</h2>
                <p className={twMerge(defaultStyles, "mb-6")}>{t("refund.pleased")}</p>
                <h2 className={titleStyles}>{t("refund.exceptions")}</h2>
                <p className={twMerge(defaultStyles, "mb-6")}>
                    <Trans
                        i18nKey="refund.damaged"
                        components={{
                            "support-link": (
                                <a
                                    href={"mailto:" + supportLink}
                                    className="text-primary underline">
                                    {supportLink}
                                </a>
                            )
                        }}
                    />
                </p>
                <p className={twMerge(defaultStyles, "text-xl text-baltic-sea")}>
                    <Trans
                        i18nKey="refund.email"
                        components={{
                            "support-link": (
                                <a
                                    href={"mailto:" + supportLink}
                                    className="text-primary underline">
                                    {supportLink}
                                </a>
                            )
                        }}
                    />
                </p>
            </section>
        </InfoWrapper>
    );
};
