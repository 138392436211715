export enum SectionId {
    HOME_PAGE = "home",
    BENEFITS = "benefits",
    CARD_TYPE = "card-type",
    HOW_IT_WORKS = "how-it-works",
    FAQ = "faq"
}

export enum ButtonType {
    GREEN = "green",
    BLACK = "black",
    OUTLINED = "outlined"
}

export enum PackageId {
    FIRST = "?packId=1",
    SECOND = "?packId=2",
    THIRD = "?packId=3"
}
