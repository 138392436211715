import { FC } from "react";
import { Link } from "react-router-dom";
import { HOME_PATH } from "router/constants";
import { twMerge } from "tailwind-merge";
import { LogoIcon } from "assets/icons/logo.icon";
import { Trans } from "react-i18next";
import { scrollToTop } from "utils/index";
import { GreenLogoIcon } from "assets/icons/green-logo.icon";

interface Props {
    className?: string;
    footer?: boolean;
}

export const Logo: FC<Props> = ({ className, footer = false }) => (
    <Link
        to={HOME_PATH}
        onClick={scrollToTop}
        className={twMerge("flex items-center", footer && "lg:mb-4", className)}>
        {footer ? <GreenLogoIcon /> : <LogoIcon />}
        <p
            className={twMerge(
                "font-normal text-5.5 ml-2 text-nowrap",
                footer && "text-athens-gray text-opacity-60"
            )}>
            <Trans
                i18nKey="navigation.logo"
                components={{
                    custom: (
                        <span
                            className={
                                footer ? "text-athens-gray text-opacity-100" : "text-opacity-100"
                            }
                        />
                    )
                }}
            />
        </p>
    </Link>
);
