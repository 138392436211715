import { FC } from "react";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";

import { Button } from "./button.component";
import { ManageAccountButtonsProps } from "types";
import { manageAccountButtons } from "constants/index";
import { SectionId } from "enums/index";
import { scrollIntoElementById } from "utils";
import { HOME_PATH } from "router/constants";
import { useNavigate } from "react-router-dom";

interface Props {
    listClassName?: string;
    itemClassName?: string;
    buttonClassName?: string;
    onButtonClick?: () => void;
    hasIcon?: boolean;
    icon?: JSX.Element;
}

export const ManageAccountList: FC<Props> = ({
    listClassName,
    itemClassName,
    buttonClassName,
    onButtonClick,
    icon
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const renderManageAccountButton = ({ name, path, type }: ManageAccountButtonsProps) => {
        const handleClick = () => {
            navigate(path === SectionId.HOME_PAGE ? HOME_PATH : `/#${path}`);
            onButtonClick?.();
            scrollIntoElementById(path);
        };

        return (
            <li
                aria-label={name}
                key={name}
                className={twMerge("flex justify-center items-center", itemClassName)}>
                <Button
                    buttonType={type}
                    className={twMerge("px-5", buttonClassName)}
                    onClick={handleClick}>
                    {t(name)}
                </Button>
                {icon}
            </li>
        );
    };

    return (
        <ul
            aria-label="Manage account buttons"
            className={twMerge("list-none lg:mr-3", listClassName)}>
            {manageAccountButtons.map(renderManageAccountButton)}
        </ul>
    );
};
