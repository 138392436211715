import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "./collapsible.ui";
import { XMarkIcon } from "assets/icons/xmark.icon";

interface Props {
    title: string;
    content: React.ReactNode;
}

export const Collapse: FC<Props> = ({ title, content }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Collapsible open={isOpen} onOpenChange={setIsOpen}>
            <CollapsibleTrigger asChild>
                <button className="w-full h-fit text-baltic-sea text-xl font-semibold justify-between text-wrap text-left flex items-center">
                    {t(title)}
                    <XMarkIcon
                        className={twMerge(
                            "rotate-90 transform transition-transform duration-100",
                            isOpen && "-rotate-90"
                        )}
                    />
                </button>
            </CollapsibleTrigger>

            <CollapsibleContent className="overflow-hidden mt-2">{content}</CollapsibleContent>
        </Collapsible>
    );
};
