import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-awesome-reveal";
import { Button } from "./button.component";
import { ButtonType } from "enums/index";
import { XMarkIcon } from "assets/icons/xmark.icon";
import { twMerge } from "tailwind-merge";

interface ModalProps extends PropsWithChildren {
    isOpen: boolean;
    onClose: () => void;
    onChoose: (item: string) => () => void;
    header: string;
}

export const Modal: FC<ModalProps> = ({ isOpen, onClose, onChoose, header, children }) => {
    const { t } = useTranslation();
    const buttonStyle = "border-baltic-sea text-baltic-sea w-fit px-8";

    if (!isOpen) return null;

    return (
        <div className="fixed top-0 right-0 backdrop-blur-sm h-screen w-full flex justify-center items-center z-30 overflow-scroll lg:items-start">
            <Zoom triggerOnce>
                <div className="bg-white p-5 rounded-xl flex flex-col relative">
                    <h1 className="text-center text-2xl text-baltic-sea font-semibold mb-6">
                        {t("details.choose")}&nbsp;
                        <span className="lowercase text-baltic-sea">{header}</span>
                    </h1>
                    <div className="flex justify-start flex-wrap gap-2 max-w-175 lg:justify-center">
                        {children}
                    </div>
                    <div className="w-full flex justify-between">
                        <Button
                            buttonType={ButtonType.OUTLINED}
                            className={twMerge(buttonStyle, "hover:border-primary")}
                            onClick={onClose}>
                            {t("details.close")}
                        </Button>
                        <Button
                            buttonType={ButtonType.GREEN}
                            className={buttonStyle}
                            onClick={onChoose(header)}>
                            {t("details.choose")}
                        </Button>
                    </div>
                    <XMarkIcon
                        onClick={onClose}
                        className="absolute top-3 right-3 cursor-pointer"
                    />
                </div>
            </Zoom>
        </div>
    );
};
