import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { SectionId } from "enums/index";
import { Slide, Zoom } from "react-awesome-reveal";
import { HowItWorksItem } from "./common/how-it-works-item.component";
import { HowItWorksItemProps } from "types/index";
import { howItWorksArray } from "constants/index";
import HowItWorksImage from "assets/images/how-it-works.png";

export const HowItWorks: FC = () => {
    const { t } = useTranslation();

    const renderLi = (item: HowItWorksItemProps) => <HowItWorksItem {...item} key={item.header} />;

    return (
        <section className="w-full mt-2 flex justify-center" id={SectionId.HOW_IT_WORKS}>
            <div className="max-w-360 w-screen py-20 lg:block lg:py-14">
                <Slide triggerOnce className="px-4">
                    <h1 className="text-manatee text-5xl font-normal text-center mb-2 leading-24 lg:text-5x">
                        <Trans
                            i18nKey={"howItWorks.header"}
                            components={{
                                custom: <span className="text-baltic-sea font-semibold" />
                            }}
                        />
                    </h1>
                    <p className="text-base text-baltic-sea mb-8 text-center">
                        {t("howItWorks.simple")}
                    </p>
                    <section className="flex justify-between mb-20 lg:flex-col">
                        <ul className="w-3/5 lg:w-full lg:mb-8">{howItWorksArray.map(renderLi)}</ul>
                        <div className="rounded-xl w-2/5 flex justify-center items-center relative bg-gradient-to-br from-stone-700 via-stone-800 to-baltic-sea lg:w-full">
                            <img
                                src={HowItWorksImage}
                                alt="Silver Card"
                                className="absolute -top-14 lg:relative lg:-top-6"
                            />
                        </div>
                    </section>
                </Slide>
                <Zoom triggerOnce>
                    <div className="p-20 rounded-10 bg-[radial-gradient(circle_at_top,_var(--tw-gradient-stops))] from-stone-800 via-baltic-sea to-baltic-sea lg:py-10 lg:px-4 lg:rounded-none">
                        <h1 className="bg-gradient-to-b from-athens-gray to-primary bg-clip-text text-transparent text-center text-10 leading-14.5 font-medium lg:text-2xl lg:leading-8">
                            {t("howItWorks.elevate")}
                        </h1>
                    </div>
                </Zoom>
            </div>
        </section>
    );
};
