import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { SectionId } from "enums/index";
import { Zoom } from "react-awesome-reveal";
import { CardTypeItem } from "./common/card-type-item.component";
import { CardTypeItemProps } from "types/index";
import { cardTypeArray } from "constants/index";

export const CardType: FC = () => {
    const { t } = useTranslation();

    const renderLi = (item: CardTypeItemProps) => <CardTypeItem {...item} key={item.header} />;

    return (
        <section className="w-full mt-2 flex justify-center" id={SectionId.CARD_TYPE}>
            <Zoom triggerOnce>
                <div className="max-w-360 w-screen py-20 px-4 rounded-10 bg-[radial-gradient(circle_at_top,_var(--tw-gradient-stops))] from-stone-800 via-baltic-sea to-baltic-sea lg:block lg:py-14 lg:rounded-none">
                    <h1 className="text-manatee text-5xl font-normal text-center mb-2 leading-14.5 bg-gradient-to-b from-athens-gray to-manatee bg-clip-text text-transparent lg:text-4xl">
                        <Trans i18nKey={"cardType.header"} />
                    </h1>
                    <p className="text-base text-athens-gray text-opacity-60 mb-8 text-center">
                        {t("benefits.business")}
                    </p>
                    <ul className="w-full flex justify-between px-20 lg:flex-col lg:items-center lg:px-4">
                        {cardTypeArray.map(renderLi)}
                    </ul>
                </div>
            </Zoom>
        </section>
    );
};
