import { FC, SVGProps } from "react";

export const LogoIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg" width={36} {...props}>
        <rect y="0.862305" width="36" height="36" rx="4.36364" fill="#1C1A1F" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.7513 16.412C26.0525 15.8903 26.1458 15.0028 25.9596 14.4298L23.9446 8.22804C23.7584 7.65504 23.3632 7.61351 23.062 8.13528L17.9713 16.9526L16.3077 11.8326C16.1215 11.2595 15.7264 11.218 15.4251 11.7398L9.74729 21.5741C9.44604 22.0959 9.35276 22.9834 9.53895 23.5564L11.554 29.7581C11.7402 30.3311 12.1353 30.3726 12.4366 29.8508L17.5273 21.0335L19.1909 26.1536C19.3771 26.7266 19.7722 26.7681 20.0734 26.2463L25.7513 16.412Z"
            fill="#9FFE05"
        />
    </svg>
);
