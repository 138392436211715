import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Slide } from "react-awesome-reveal";
import { Agreement } from "components/terms/agreement.component";
import { Intellectual } from "components/terms/intellectual.component";
import { Representations } from "components/terms/representations.component";
import { Products } from "components/terms/products.component";
import { Payment } from "components/terms/payment.component";
import { ReturnPolicy } from "components/terms/returnPolicy.component";
import { Activities } from "components/terms/activities.component";
import { Generated } from "components/terms/generated.component";
import { Contribution } from "components/terms/contribution.component";
import { Submissions } from "components/terms/submissions.component";
import { SiteManagment } from "components/terms/siteManagment.component";
import { PrivacyPolicy } from "components/terms/privacyPolicy.component";
import { Termiation } from "components/terms/termiation.component";
import { Modifications } from "components/terms/modifications.component";
import { Governing } from "components/terms/governing.component";
import { Dispute } from "components/terms/dispute.component";
import { Corrections } from "components/terms/corrections.component";
import { Disclamer } from "components/terms/disclamer.component";
import { Limitations } from "components/terms/limitations.component";
import { Indemnification } from "components/terms/indemnification.component";
import { UserData } from "components/terms/user-data.component";
import { Electronic } from "components/terms/electronic.component";
import { Miscellaneous } from "components/terms/miscellaneous.component";
import { Contact } from "components/terms/contact.component";
import { scrollToTop } from "utils";
import { InfoWrapper } from "components/common/info-wrapper.component";

interface LiItem {
    header: string;
    content: JSX.Element;
}

export const Terms: FC = () => {
    const { t } = useTranslation();
    const defaultStyles = "text-base font-normal leading-6";

    const termsItems = [
        {
            header: "agreement",
            content: <Agreement className={defaultStyles} />
        },
        {
            header: "intellectual",
            content: <Intellectual className={defaultStyles} />
        },
        {
            header: "representations",
            content: <Representations className={defaultStyles} />
        },
        {
            header: "products",
            content: <Products className={defaultStyles} />
        },
        {
            header: "payment",
            content: <Payment className={defaultStyles} />
        },
        {
            header: "returnPolicy",
            content: <ReturnPolicy className={defaultStyles} />
        },
        {
            header: "activities",
            content: <Activities className={defaultStyles} />
        },
        {
            header: "generated",
            content: <Generated className={defaultStyles} />
        },
        {
            header: "contribution",
            content: <Contribution className={defaultStyles} />
        },
        {
            header: "submissions",
            content: <Submissions className={defaultStyles} />
        },
        {
            header: "siteManagment",
            content: <SiteManagment className={defaultStyles} />
        },
        {
            header: "privacyPolicy",
            content: <PrivacyPolicy className={defaultStyles} />
        },
        {
            header: "termiation",
            content: <Termiation className={defaultStyles} />
        },
        {
            header: "modifications",
            content: <Modifications className={defaultStyles} />
        },
        {
            header: "governing",
            content: <Governing className={defaultStyles} />
        },
        {
            header: "dispute",
            content: <Dispute className={defaultStyles} />
        },
        {
            header: "corrections",
            content: <Corrections className={defaultStyles} />
        },
        {
            header: "disclamer",
            content: <Disclamer className={defaultStyles} />
        },
        {
            header: "limitations",
            content: <Limitations className={defaultStyles} />
        },
        {
            header: "indemnification",
            content: <Indemnification className={defaultStyles} />
        },
        {
            header: "userData",
            content: <UserData className={defaultStyles} />
        },
        {
            header: "electronic",
            content: <Electronic className={defaultStyles} />
        },
        {
            header: "miscellaneous",
            content: <Miscellaneous className={defaultStyles} />
        },
        {
            header: "contact",
            content: <Contact className={defaultStyles} />
        }
    ];
    const renderLi = ({ header, content }: LiItem) => (
        <Slide triggerOnce key={header}>
            <li className="font-semibold text-xl text-baltic-sea mb-4">
                {t(`terms.${header}`)}
                {content}
            </li>
        </Slide>
    );

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <InfoWrapper header="terms.header" arrow={true}>
            <ul className="list-decimal list-inside w-9/12 lg:w-full">
                {termsItems.map(renderLi)}
            </ul>
        </InfoWrapper>
    );
};
