import React, { FC } from "react";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";

import { NavigationLinkProps } from "types";

interface Props extends NavigationLinkProps {
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLLIElement>) => void;
}

export const NavigationLink: FC<Props> = ({ id, name, href, className, onClick }) => {
    const { t } = useTranslation();

    const itemStyles =
        "h-full flex justify-center items-center px-8 text-sm hover:text-opacity-100 text-nowrap font-normal leading-5 cursor-pointer duration-300 border-r border-baltic-sea border-opacity-10 last:border-none lg:border-none";

    return (
        <li
            key={id}
            aria-label={`Link to ${t(name)}`}
            className={twMerge(!href && itemStyles, className)}
            onClick={onClick}>
            {href ? (
                <a
                    href={href}
                    rel="noreferrer noopener"
                    className={twMerge(itemStyles, className, "py-0")}>
                    {t(name)}
                </a>
            ) : (
                t(name)
            )}
        </li>
    );
};
