import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ItemWithModalObject } from "types/index";

interface Props {
    header: string;
    chosen: ItemWithModalObject | null;
}

export const ChosenItem: FC<Props> = ({ header, chosen }) => {
    const { t } = useTranslation();

    return (
        <div>
            <h3 className="text-baltic-sea font-semibold">{header}</h3>
            {chosen && (
                <div className="flex items-center mt-4">
                    <div className="h-16 mr-8">
                        <img
                            src={chosen.image}
                            alt={chosen.header}
                            className="rounded-lg bg-baltic-sea max-w-28 h-full"
                        />
                    </div>
                    <div>
                        <h1 className="text-baltic-sea text-base font-semibold md:text-sm">
                            {chosen.header}
                        </h1>
                        {chosen.size && (
                            <p className="text-sm">
                                {t("cardType.cardSize")}
                                <span className="text-baltic-sea"> {chosen.size}</span>
                            </p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
