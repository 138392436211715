import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import toast from "react-hot-toast";
import { useForm, SubmitHandler } from "react-hook-form";

import { Wrapper } from "components/common/wrapper.component";
import { SelectItem } from "components/common/select-item.component";
import { OrderDetailItemWithModal } from "components/common/order-detail-item-with-modal.component";
import { QuantityItem } from "components/common/quantity-item.component";
import { Input } from "components/common/input.comoponent";
import { TotalItem } from "components/common/total-item.component";

import { Inputs, ItemQuantity, ItemWithModal } from "types/index";
import { PackageId } from "enums/index";
import { scrollToTop } from "utils/index";

import { quantityArray } from "constants/index";

import Design1 from "assets/images/design1.png";
import Design2 from "assets/images/design2.png";
import Design3 from "assets/images/design3.png";
import Design4 from "assets/images/design4.png";
import Design5 from "assets/images/design5.png";
import CardType1 from "assets/images/card-type1.png";
import CardType2 from "assets/images/card-type2.png";
import CardType3 from "assets/images/card-type3.png";

export const OrderDetails: FC = () => {
    const { t } = useTranslation();
    const [selectedFromPath, setSelectedFromPath] = useState<number>(1);
    const [type, setType] = useState<string>();
    const [design, setDesign] = useState<string>();
    const [activeQuantity, setActiveQuantity] = useState(89.99);

    const handleChangeType = (type: string) => {
        setType(type);
    };
    const handleChangeDesign = (design: string) => {
        setDesign(design);
    };
    const handleChangeQuantity = (id: number) => () => {
        setActiveQuantity(id);
    };

    const arrayItemsWithModal = [
        {
            header: "cardType",
            selectedFromPath: selectedFromPath,
            handleChange: handleChangeType,
            itemsArray: [
                {
                    id: 1,
                    image: CardType1,
                    header: t("details.standard"),
                    size: '3.5" x 2.0"',
                    value: '3.5" x 2.0"'
                },
                {
                    id: 2,
                    image: CardType2,
                    header: t("details.business"),
                    size: '2.56" x 2.56"',
                    value: '2.56" x 2.56"'
                },
                {
                    id: 3,
                    image: CardType3,
                    header: t("details.special"),
                    size: '3.3" x 2.16"',
                    value: '3.3" x 2.16"'
                }
            ]
        },
        {
            header: "cardDesign",
            selectedFromPath: 1,
            handleChange: handleChangeDesign,
            itemsArray: [
                {
                    image: Design1,
                    header: t("details.des", { designItem: 1 }),
                    value: "design1"
                },
                {
                    image: Design2,
                    header: t("details.des", { designItem: 2 }),
                    value: "design2"
                },
                {
                    image: Design3,
                    header: t("details.des", { designItem: 3 }),
                    value: "design3"
                },
                {
                    image: Design4,
                    header: t("details.des", { designItem: 4 }),
                    value: "design4"
                },
                {
                    image: Design5,
                    header: t("details.des", { designItem: 5 }),
                    value: "design5"
                }
            ]
        }
    ];

    const defaultClassName =
        "bg-white rounded-xl border border-baltic-sea border-opacity-10 p-8 h-fit lg:px-4";
    const defaultUlStyle = "w-full flex justify-between flex-wrap flex-col";

    const inputsArray = ["businessName", "address", "phone", "email"];

    const renderItem = ({ header, itemsArray, selectedFromPath, handleChange }: ItemWithModal) => (
        <OrderDetailItemWithModal
            header={t(`details.${header}`)}
            selectedFromPath={selectedFromPath}
            itemsArray={itemsArray}
            handleChange={handleChange}
            key={header}
        />
    );
    const renderInputs = (item: string) => (
        <Input register={register} label={item} required errors={errors} key={item} />
    );
    const renderQuantityItem = ({ id, price }: ItemQuantity) => (
        <QuantityItem
            id={id}
            price={price}
            activeQuantity={activeQuantity}
            handleChangeQuantity={handleChangeQuantity}
            key={id}
        />
    );

    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        reset
    } = useForm<Inputs>({
        mode: "all"
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        const fullData = {
            quantity: activeQuantity,
            type,
            design,
            ...data
        };
        console.log(fullData);
        try {
            toast.error(t("messages.error"));
        } catch (error: any) {
            toast.error(t("messages.sent"));
        }
        reset();
    };

    useEffect(() => {
        scrollToTop();
        switch (window.location.search) {
            case PackageId.FIRST:
                setSelectedFromPath(1);
                break;
            case PackageId.SECOND:
                setSelectedFromPath(2);
                break;
            case PackageId.THIRD:
                setSelectedFromPath(3);
                break;
            default:
                setSelectedFromPath(1);
                break;
        }
    }, []);
    return (
        <Wrapper
            header="details.header"
            className="pb-10"
            sectionClassName="flex justify-between lg:flex-col">
            <div className={twMerge(defaultClassName, "w-3/5 lg:w-full lg:mb-8")}>
                <h2 className="font-semibold text-xl text-baltic-sea mb-2">{t("details.order")}</h2>
                <p className="text-sm mb-6">{t("details.design")}</p>
                {arrayItemsWithModal.map(renderItem)}
                <SelectItem header={t("details.personal")}>
                    <ul className={defaultUlStyle}>{quantityArray.map(renderQuantityItem)}</ul>
                </SelectItem>
                <SelectItem header={t("details.personal")}>
                    <form className="w-full flex flex-col" onSubmit={handleSubmit(onSubmit)}>
                        {inputsArray.map(renderInputs)}
                    </form>
                </SelectItem>
            </div>

            <TotalItem
                defaultClassName={twMerge(defaultClassName, "lg:w-full lg:mb-10")}
                activeQuantity={activeQuantity}
                isValid={isValid}
                handleSubmit={handleSubmit(onSubmit)}
            />
        </Wrapper>
    );
};
