import { FC, SVGProps } from "react";

export const GreenLogoIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg" width={36} {...props}>
        <rect y="0.708496" width="36" height="36" rx="4.36364" fill="#9FFE05" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.7513 16.2582C26.0526 15.7364 26.1459 14.849 25.9597 14.2759L23.9446 8.07424C23.7584 7.50123 23.3633 7.4597 23.0621 7.98147L17.9714 16.7988L16.3078 11.6787C16.1216 11.1057 15.7264 11.0642 15.4252 11.586L9.74735 21.4203C9.4461 21.9421 9.35283 22.8296 9.53901 23.4026L11.5541 29.6043C11.7402 30.1773 12.1354 30.2188 12.4366 29.697L17.5273 20.8797L19.1909 25.9998C19.3771 26.5728 19.7722 26.6143 20.0735 26.0925L25.7513 16.2582Z"
            fill="#1C1A1F"
        />
    </svg>
);
