import { brandName } from "constants/index";
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
    className: string;
}

export const Collect: FC<Props> = ({ className }) => {
    const { t } = useTranslation();

    return (
        <>
            <p className={twMerge(className, "mt-3 mb-4")}>
                <Trans
                    i18nKey="privacyPolicy.website"
                    components={{
                        "website-name": <span>{brandName}</span>
                    }}
                />
            </p>
            <p className={twMerge(className, "mb-4")}>{t("privacyPolicy.browsers")}</p>
            <p className={twMerge(className, "mb-4")}>{t("privacyPolicy.store")}</p>
            <p className={twMerge(className, "mb-4")}>
                <Trans
                    i18nKey="privacyPolicy.receive"
                    components={{
                        "website-name": <span>{brandName}</span>
                    }}
                />
            </p>
            <p className={className}>{t("privacyPolicy.advertising")}</p>
        </>
    );
};
