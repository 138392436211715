import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Slide } from "react-awesome-reveal";
import { privacyContent, supportLink } from "constants/index";
import { NavigationLinkProps, PrivacyProps } from "types/index";
import { twMerge } from "tailwind-merge";
import { Link } from "react-router-dom";
import { Logo } from "./common/logo.component";

interface Item {
    title: string;
    items: NavigationLinkProps[] | PrivacyProps[] | string[];
}

const itemArray = [
    {
        title: "policies",
        items: privacyContent
    },
    {
        title: "contacts",
        items: [
            "3450 N. Triumph Blvd., Suite 102, Lehi,<br /> Utah 84043 USA <br />+ 1 (833) 3338103"
        ]
    }
];

export const Footer: FC = () => {
    const { t } = useTranslation();

    const defaultStyles =
        "text-athens-gray text-sm mb-2 last:mb-0 transition-all hover:text-opacity-65";

    const renderButtons = (item: NavigationLinkProps | PrivacyProps | string) => {
        if (typeof item === "string") {
            return (
                <li
                    className={twMerge(defaultStyles, "flex flex-col hover:text-opacity-100")}
                    key={item}>
                    <Trans i18nKey={"custom"}>{item}</Trans>
                    <a className="text-primary underline mt-3" href={`mailto:${supportLink}`}>
                        {supportLink}
                    </a>
                </li>
            );
        }

        if ("name" in item) {
            return (
                <li className={defaultStyles} key={item.name}>
                    <Link to={item.id}>{t(item.name)}</Link>
                </li>
            );
        }

        return null;
    };

    const renderLinks = (item: Item) => (
        <ul className="lg:mb-4 lg:last:mb-0" key={item.title}>
            <p className="text-sm text-athens-gray text-opacity-40 mb-2">
                {t(`footer.${item.title}`)}
            </p>
            {item.items.map(renderButtons)}
        </ul>
    );

    return (
        <footer className="flex justify-center bg-[radial-gradient(circle_at_top,_var(--tw-gradient-stops))] from-stone-800 via-baltic-sea to-baltic-sea">
            <section
                aria-label="Footer"
                className="w-full max-w-360 py-8 px-9 mt-2 flex items-start justify-between lg:px-4 lg:py-8 lg:flex-col">
                <Slide triggerOnce>
                    <Logo footer={true} />
                    {itemArray.map(renderLinks)}
                </Slide>
            </section>
        </footer>
    );
};
