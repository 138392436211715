import { FC, SVGProps } from "react";

export const NotCompleteIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" width={17} {...props}>
        <path
            d="M8.49406 15.1667C7.17552 15.1667 5.88659 14.7757 4.79026 14.0432C3.69393 13.3106 2.83945 12.2694 2.33487 11.0513C1.83028 9.83309 1.69826 8.49265 1.95549 7.19944C2.21273 5.90624 2.84767 4.71835 3.78002 3.786C4.71237 2.85365 5.90025 2.21871 7.19346 1.96148C8.48667 1.70424 9.82711 1.83626 11.0453 2.34085C12.2635 2.84543 13.3046 3.69991 14.0372 4.79624C14.7697 5.89257 15.1607 7.1815 15.1607 8.50004C15.1607 10.2682 14.4584 11.9638 13.2081 13.2141C11.9579 14.4643 10.2622 15.1667 8.49406 15.1667ZM8.49406 3.16671C7.43923 3.16671 6.40808 3.47951 5.53102 4.06554C4.65396 4.65157 3.97037 5.48453 3.5667 6.45907C3.16304 7.4336 3.05742 8.50596 3.26321 9.54053C3.469 10.5751 3.97695 11.5254 4.72283 12.2713C5.46871 13.0172 6.41902 13.5251 7.45358 13.7309C8.48815 13.9367 9.5605 13.8311 10.535 13.4274C11.5096 13.0237 12.3425 12.3401 12.9286 11.4631C13.5146 10.586 13.8274 9.55488 13.8274 8.50004C13.8274 7.08556 13.2655 5.729 12.2653 4.72881C11.2651 3.72861 9.90855 3.16671 8.49406 3.16671Z"
            fill="#1C1A1F"
            fillOpacity="0.24"
        />
    </svg>
);
