import { FC, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Slide } from "react-awesome-reveal";
import { twMerge } from "tailwind-merge";
import { Information } from "components/privacy/information.component";
import { Collect } from "components/privacy/collect.component";
import { Contact } from "components/privacy/contact.component";
import { originLink, path } from "constants/index";
import { scrollToTop } from "utils";
import { InfoWrapper } from "components/common/info-wrapper.component";

interface LiItem {
    header: string;
    content: JSX.Element;
}

export const Privacy: FC = () => {
    const { t } = useTranslation();
    const defaultStyles = "text-base font-normal leading-6";

    const textsArray = ["explains", "collected", "knowingly", "useYour", "share"];

    const privacyItems = [
        {
            header: "information",
            content: <Information className={defaultStyles} />
        },
        {
            header: "collect",
            content: <Collect className={defaultStyles} />
        },
        {
            header: "contact",
            content: <Contact className={defaultStyles} />
        }
    ];
    const renderLi = ({ header, content }: LiItem) => (
        <Slide triggerOnce key={header}>
            <li className="font-semibold text-xl text-baltic-sea mb-4">
                {t(`privacyPolicy.${header}`)}
                {content}
            </li>
        </Slide>
    );
    const renderP = (item: string) => (
        <p className={twMerge(defaultStyles, "mb-4")} key={item}>
            <Trans
                i18nKey={"privacyPolicy." + item}
                components={{
                    "web-link": <span>{originLink}</span>,
                    "path-link": <span>{path}</span>
                }}
            />
        </p>
    );

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <InfoWrapper header="privacyPolicy.header" arrow={true}>
            <section className="w-9/12 lg:w-full">
                {textsArray.map(renderP)}
                <ul className="list-none ">{privacyItems.map(renderLi)}</ul>
            </section>
        </InfoWrapper>
    );
};
