import { FC } from "react";
import { useTranslation } from "react-i18next";
import { BenefitsItemProps } from "types/index";

export const BenefitsItem: FC<BenefitsItemProps> = ({ header, text }) => {
    const { t } = useTranslation();

    return (
        <li className="px-8 py-6 h-72 flex flex-col justify-between rounded-xl bg-white w-120/4 shadow-lg lg:w-full lg:mb-4 lg:last:mb-0 lg:h-52">
            <h1 className="text-7 font-medium text-baltic-sea">{t(header)}</h1>
            <p className="text-sm font-base">{t(text)}</p>
        </li>
    );
};
