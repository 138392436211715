import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { ItemWithModal, ItemWithModalObject } from "types/index";
import { Modal } from "./modal.component";
import { ModalItem } from "./modal-item.component";
import { ChosenItem } from "./chosen-item.component";

export const OrderDetailItemWithModal: FC<ItemWithModal> = ({
    header,
    itemsArray,
    selectedFromPath,
    handleChange
}) => {
    const { t } = useTranslation();

    const [isModalActive, setIsModalActive] = useState(false);
    const [selected, setSelected] = useState<ItemWithModalObject | null>(null);
    const [chosen, setChosen] = useState<ItemWithModalObject | null>(null);

    const defaultClassName =
        "bg-white rounded-xl border border-baltic-sea border-opacity-10 p-8 h-fit";

    const toggleModal = () => setIsModalActive(!isModalActive);

    const selectType = (item: ItemWithModalObject) => () => {
        setSelected(item);
        handleChange(item.value);
    };

    const handleChoose = () => () => {
        setChosen(selected);
        toggleModal();
    };

    const render = (item: ItemWithModalObject) => (
        <ModalItem item={item} selectType={selectType} selected={selected} key={item.header} />
    );

    useEffect(() => {
        const selectedObj = itemsArray[selectedFromPath - 1];
        setChosen(selectedObj);
        setSelected(selectedObj);
        handleChange(selectedObj.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFromPath]);

    useEffect(() => {
        setSelected(chosen);
    }, [chosen, isModalActive]);

    return (
        <section className={twMerge(defaultClassName, "flex justify-between p-4 mb-2")}>
            <ChosenItem header={header} chosen={chosen} />
            <button
                className="text-bahia transition-all hover:text-opacity-60"
                onClick={toggleModal}>
                {chosen ? t("details.edit") : t("details.choose")}
            </button>
            <Modal
                isOpen={isModalActive}
                onClose={toggleModal}
                onChoose={handleChoose}
                header={header}>
                {itemsArray?.map(render)}
            </Modal>
        </section>
    );
};
