import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Button } from "./common/button.component";
import { ButtonType, SectionId } from "enums/index";
import { Zoom } from "react-awesome-reveal";
import { scrollIntoElementById } from "utils";
import { ArrowIcon } from "assets/icons/arrow.icon";
import { HeaderCards } from "assets/icons/header-cards.icon";

export const Header: FC = () => {
    const { t } = useTranslation();
    const handleClick = (path: SectionId) => () => {
        scrollIntoElementById(path);
    };

    return (
        <header className="overflow-hidden flex flex-col items-center">
            <Zoom triggerOnce>
                <section className="w-screen relative bg-header bg-contain bg-repeat-round min-h-full max-w-360 flex flex-col items-center lg:block lg:min-h-full lg:overflow-hidden lg:bg-cover lg:bg-center lg:bg-no-repeat lg:px-4">
                    <h1 className="text-manatee text-20 font-light text-center pt-24 mb-2 leading-24 lg:text-5xl">
                        <Trans
                            i18nKey="header.comeToLife"
                            components={{
                                custom: <span className="text-athens-gray font-medium" />
                            }}
                        />
                    </h1>
                    <p className="text-athens-gray text-opacity-60 text-base text-center font-normal mb-6">
                        {t("header.original")}
                    </p>
                    <li className="flex justify-center items-center lg:mx-4">
                        <Button
                            buttonType={ButtonType.GREEN}
                            className="w-fit px-8 lg:w-full lg:flex lg:justify-center "
                            onClick={handleClick(SectionId.CARD_TYPE)}>
                            {t("navigation.card")}
                        </Button>
                        <span className="h-11 px-3.25 bg-primary rounded-full flex justify-center items-center">
                            <ArrowIcon fill="#1C1A1F" />
                        </span>
                    </li>
                    <HeaderCards className="lg:w-full lg:mt-12" />
                </section>
            </Zoom>
        </header>
    );
};
