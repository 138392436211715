import { FC, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";
import { ButtonToTop } from "./button-to-top.component";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { HOME_PATH } from "router/constants";

interface Props extends PropsWithChildren {
    className?: string;
    classNameHeader?: string;
    sectionClassName?: string;
    header: string;
    arrow?: boolean;
}

export const Wrapper: FC<Props> = ({
    className,
    classNameHeader,
    header,
    children,
    sectionClassName,
    arrow = false
}) => {
    const { t } = useTranslation();
    return (
        <main
            className={twMerge(
                "w-full flex flex-col justify-start items-center pt-10 lg:pb-0",
                className
            )}>
            <div className="max-w-360 px-4 w-full lg:px-0">
                <nav className="flex items-center list-none mb-3 text-opacity-40 lg:px-4">
                    <li className="transition-all flex items-center hover:opacity-50">
                        <Link to={HOME_PATH} className="text-sm">
                            {t("navigation.homepage")}
                        </Link>
                    </li>
                    &nbsp;/&nbsp;
                    <li className="text-sm text-opacity-100">
                        {t(header === "details.header" ? "details.order" : header)}
                    </li>
                </nav>
                <h1
                    className={twMerge(
                        "text-4xl font-normal mb-8 lg:text-3xl lg:px-4",
                        classNameHeader
                    )}>
                    <Trans
                        i18nKey={header}
                        components={{
                            custom: <span className="text-opacity-100 font-semibold" />
                        }}
                    />
                </h1>
                <section
                    aria-label={t(header)}
                    className={twMerge("relative w-full", sectionClassName)}>
                    {children}
                </section>
                {arrow && <ButtonToTop />}
            </div>
        </main>
    );
};
