import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PRIVACY } from "router/constants";
import { twMerge } from "tailwind-merge";
import { Button } from "./button.component";
import { ButtonType } from "enums/index";

interface Props {
    defaultClassName: string;
    activeQuantity: number;
    isValid: boolean;
    handleSubmit: () => void;
}

export const TotalItem: FC<Props> = ({
    defaultClassName,
    activeQuantity,
    isValid,
    handleSubmit
}) => {
    const { t } = useTranslation();

    return (
        <section className={twMerge("w-1/3", defaultClassName)}>
            <h1 className="text-xl text-baltic-sea font-semibold mb-3">{t("details.total")}</h1>
            <section className="mb-3">
                <p className="py-3 border-b border-baltic-sea border-opacity-10 flex justify-between items-center">
                    <span className="text-sm">{t("details.time")}</span>
                    <span className="text-sm font-semibold text-opacity-100">
                        {t("details.days")}
                    </span>
                </p>
                <p className="py-3 flex justify-between items-center">
                    <span className="text-sm">{t("details.price")}</span>
                    <span className="text-base font-semibold text-opacity-100">
                        ${activeQuantity}
                    </span>
                </p>
            </section>
            <Button
                buttonType={ButtonType.GREEN}
                className={twMerge(
                    "flex justify-center mb-3",
                    !isValid && "pointer-events-none opacity-35"
                )}
                onClick={handleSubmit}>
                {t("details.purchase")}
            </Button>
            <p className="text-xs">
                <Trans
                    i18nKey="details.clicking"
                    components={{
                        custom: <Link to={PRIVACY} />
                    }}
                />
            </p>
        </section>
    );
};
