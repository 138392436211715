import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { ButtonType } from "enums";

interface IProps extends PropsWithChildren {
    className?: string;
    type?: "button" | "submit";
    onClick?: () => void;
    arialLabel?: string;
    buttonType?: string;
    icon?: JSX.Element;
}

export const Button = ({
    className,
    type = "button",
    children,
    onClick,
    arialLabel,
    buttonType = ButtonType.BLACK,
    icon
}: IProps) => (
    <button
        aria-label={arialLabel}
        type={type}
        className={twMerge(
            "w-full h-11 px-4 rounded-full text-sm text-nowrap font-medium leading-5 cursor-pointer duration-300 flex items-center",
            buttonType === ButtonType.BLACK && "bg-baltic-sea hover:bg-opacity-70 text-athens-gray",
            buttonType === ButtonType.GREEN && "text-baltic-sea bg-primary hover:bg-opacity-70",
            buttonType === ButtonType.OUTLINED &&
                "bg-transparent border border-primary text-primary hover:text-baltic-sea hover:bg-primary",
            className
        )}
        onClick={onClick}>
        {children}
        {icon}
    </button>
);
