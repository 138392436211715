import { CompleteIcon } from "assets/icons/complete.icon";
import { NotCompleteIcon } from "assets/icons/not-complete.icon";
import { FC } from "react";
import { ItemWithModalObject } from "types/index";
import { twMerge } from "tailwind-merge";

interface Props {
    selected: ItemWithModalObject | null;
    selectType: (item: ItemWithModalObject) => () => void;
    item: ItemWithModalObject;
}

export const ModalItem: FC<Props> = ({ selected, selectType, item }) => (
    <button
        className={twMerge(
            "border rounded-lg transition-all hover:border-bahia mb-4 lg:max-w-56 lg:w-full",
            selected?.header === item.header && "border-bahia"
        )}
        onClick={selectType(item)}
        key={item.header}>
        <div className="h-40 lg:flex lg:justify-center">
            <img
                src={item.image}
                alt={item.header}
                className="rounded-t-lg bg-baltic-sea max-w-56 h-full lg:w-full"
            />
        </div>

        <div className="text-start flex justify-between px-4 py-2">
            <div className="w-3/5">
                <h1 className="text-sm text-baltic-sea">{item.header}</h1>
                <p className="text-sm"> {item.size}</p>
            </div>
            {selected?.header === item.header ? <CompleteIcon /> : <NotCompleteIcon />}
        </div>
    </button>
);
