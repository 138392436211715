import { FC, SVGProps } from "react";

export const ArrowIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg
        viewBox="0 0 16 17"
        fill="current"
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.7762 11.2585C11.6437 11.2585 11.5167 11.2058 11.423 11.1121C11.3293 11.0184 11.2766 10.8913 11.2766 10.7588L11.2766 6.30879L4.58733 12.998C4.49356 13.0918 4.36638 13.1445 4.23377 13.1445C4.10116 13.1445 3.97399 13.0918 3.88022 12.998C3.78645 12.9043 3.73377 12.7771 3.73377 12.6445C3.73377 12.5119 3.78645 12.3847 3.88022 12.2909L10.5694 5.60168L6.11939 5.60168C6.05229 5.60405 5.98539 5.59288 5.9227 5.56883C5.86001 5.54479 5.8028 5.50836 5.75449 5.46173C5.70618 5.41509 5.66776 5.35921 5.64152 5.2974C5.61528 5.2356 5.60175 5.16914 5.60175 5.10199C5.60175 5.03485 5.61528 4.96839 5.64152 4.90658C5.66776 4.84478 5.70618 4.78889 5.75449 4.74226C5.8028 4.69562 5.86001 4.6592 5.9227 4.63515C5.98539 4.61111 6.05229 4.59993 6.11939 4.6023L11.7762 4.6023C11.9087 4.60239 12.0358 4.65506 12.1295 4.74875C12.2232 4.84244 12.2759 4.96949 12.2759 5.10199L12.2759 10.7588C12.2759 10.8913 12.2232 11.0184 12.1295 11.1121C12.0358 11.2058 11.9087 11.2585 11.7762 11.2585Z"
            fill="current"
        />
    </svg>
);
