import { SectionId } from "../enums";

type ScrollBehavior = "auto" | "smooth";

interface GenerateLinkProps {
    module: string;
    additionalPath?: string;
    link?: string;
}

export const scrollIntoElementById = (
    id: SectionId | string,
    behavior: ScrollBehavior = "smooth"
) =>
    document.getElementById(id)?.scrollIntoView({
        behavior: behavior,
        block: "start"
    });

export const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
};

export const generateLink = ({ module, additionalPath, link }: GenerateLinkProps) => {
    const baselink = link ?? process.env.REACT_APP_BASE_LINK;

    if (baselink) {
        const generatedLink = baselink.replace("~module~", module);
        return `${generatedLink}${additionalPath ?? ""}`;
    }
    return "";
};
