import { FC } from "react";
import { Trans } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { phone, supportLink } from "constants/index";

interface Props {
    className: string;
}

export const Contact: FC<Props> = ({ className }) => {
    return (
        <>
            <p className={twMerge(className, "mt-3 mb-6")}>
                <Trans i18nKey="terms.resolve" />
            </p>
            <p
                className={twMerge(
                    className,
                    "text-baltic-sea font-semibold pb-8 border-b border-baltic-sea border-opacity-20 w-fit mb-4"
                )}>
                <Trans i18nKey="terms.quickly" />
            </p>
            <p className={twMerge(className, "my-1")}>
                <Trans
                    i18nKey="terms.phone"
                    components={{
                        phone: (
                            <a className="text-baltic-sea font-semibold" href={"tel:" + phone}>
                                {phone}
                            </a>
                        )
                    }}
                />
            </p>
            <p className={className}>
                <Trans
                    i18nKey="terms.email"
                    components={{
                        "support-link": (
                            <a className="text-primary underline" href={"mailto:" + supportLink}>
                                {supportLink}
                            </a>
                        )
                    }}
                />
            </p>
        </>
    );
};
