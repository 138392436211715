import { RouteObject } from "react-router-dom";
import { Home } from "pages/home.page";

import { HOME_PATH, DETAILS, TERMS, PRIVACY, REFUND, SHIPPING } from "./constants";

import { MainLayout } from "components/main-layout.component";

import ErrorPage from "pages/error-page";
import { OrderDetails } from "pages/order-details.page";
import { Terms } from "pages/terms.page";
import { Privacy } from "pages/privacy-policy.page";
import { Refund } from "pages/refund.page";
import { ShippingPolicy } from "pages/shippinng-policy.page";

export const routes: RouteObject[] = [
    {
        path: HOME_PATH,
        element: <MainLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: HOME_PATH,
                element: <Home />
            },
            {
                path: DETAILS,
                element: <OrderDetails />
            },
            {
                path: TERMS,
                element: <Terms />
            },
            {
                path: PRIVACY,
                element: <Privacy />
            },
            {
                path: REFUND,
                element: <Refund />
            },
            {
                path: SHIPPING,
                element: <ShippingPolicy />
            }
        ]
    }
];
