import { CompleteIcon } from "assets/icons/complete.icon";
import { NotCompleteIcon } from "assets/icons/not-complete.icon";
import { FC } from "react";
import { Zoom } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { ItemQuantity } from "types/index";

interface Props extends ItemQuantity {
    activeQuantity: number;
    handleChangeQuantity: (price: number) => () => void;
}

export const QuantityItem: FC<Props> = ({ id, activeQuantity, price, handleChangeQuantity }) => {
    const { t } = useTranslation();

    return (
        <Zoom triggerOnce>
            <li
                className={twMerge(
                    "w-full flex justify-between p-4 rounded-lg border border-transparent hover:border-primary transition-all cursor-pointer mb-2 last:mb-0",
                    activeQuantity === price && "border-primary"
                )}
                onClick={handleChangeQuantity(price)}>
                <div className="flex justify-between items-center">
                    {activeQuantity === price ? <CompleteIcon /> : <NotCompleteIcon />}
                    <h2 className="text-sm font-normal relative flex w-full ml-3">
                        <span className="text-baltic-sea">{id}</span>&nbsp;
                        <p className="w-full flex justify-between text-baltic-sea">
                            {t("details.cards")}
                        </p>
                    </h2>
                </div>
                <p className="text-sm text-baltic-sea font-semibold">${price}</p>
            </li>
        </Zoom>
    );
};
