import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "./button.component";
import { ButtonType } from "enums/index";
import { CardTypeItemProps } from "types/index";
import { useNavigate } from "react-router-dom";
import { DETAILS } from "router/constants";

export const CardTypeItem: FC<CardTypeItemProps> = ({ image, header, size, btnText, path }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleClick = (path: string) => () => {
        navigate(DETAILS + path);
    };

    return (
        <li className="flex flex-col rounded-xl bg-baltic-sea w-120/4 border border-athens-gray border-opacity-10 transition-all hover:bg-light-baltic-sea hover:scale-105 lg:w-fit lg:mb-4 lg:last:mb-0 ">
            <img src={image} alt={t(header)} width={400} />
            <section className="p-6">
                <h1 className="text-xl font-semibold mb-2 text-athens-gray">{t(header)}</h1>
                <p className="text-athens-gray text-opacity-40 text-sm font-normal mb-5">
                    {t("cardType.cardSize")}&nbsp;
                    <span className="text-athens-gray"> {size}</span>
                </p>
                <Button
                    buttonType={ButtonType.OUTLINED}
                    className="w-fit px-6"
                    onClick={handleClick(path)}>
                    {t(btnText)}
                </Button>
            </section>
        </li>
    );
};
