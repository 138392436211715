import { FC } from "react";
import { useTranslation } from "react-i18next";
import { HowItWorksItemProps } from "types/index";

export const HowItWorksItem: FC<HowItWorksItemProps> = ({ header, text }) => {
    const { t } = useTranslation();

    return (
        <li className="w-11/12 border-l-4 border-primary px-8 py-6 rounded-xl bg-white shadow-xl mb-4 last:mb-0 lg:w-full">
            <h1 className="text-xl text-baltic-sea mb-4">{t(header)}</h1>
            <p className="text-sm font-normal">{t(text)}</p>
        </li>
    );
};
