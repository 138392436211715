import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { SectionId } from "enums/index";
import { Slide } from "react-awesome-reveal";
import { BenefitsItem } from "./common/benefits-item.component";
import { BenefitsItemProps } from "types/index";
import { benefitsArray } from "constants/index";

export const Benefits: FC = () => {
    const { t } = useTranslation();

    const renderLi = (item: BenefitsItemProps) => <BenefitsItem {...item} key={item.header} />;

    return (
        <section className="w-full mt-2 flex justify-center" id={SectionId.BENEFITS}>
            <Slide triggerOnce>
                <div className="max-w-360 w-screen py-20 px-4 lg:block lg:py-14">
                    <h1 className="text-manatee text-5xl font-normal text-center mb-2 leading-24 lg:text-5x">
                        <Trans
                            i18nKey={"benefits.header"}
                            components={{
                                custom: <span className="text-baltic-sea font-semibold" />
                            }}
                        />
                    </h1>
                    <p className="text-base text-baltic-sea mb-8 text-center">
                        {t("benefits.business")}
                    </p>
                    <ul className="w-full flex justify-between lg:flex-col">
                        {benefitsArray.map(renderLi)}
                    </ul>
                </div>
            </Slide>
        </section>
    );
};
