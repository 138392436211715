import { FC, SVGProps } from "react";

export const CompleteIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" width={17} {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.24699 15.1667C11.9289 15.1667 14.9137 12.1819 14.9137 8.50004C14.9137 4.81814 11.9289 1.83337 8.24699 1.83337C4.56509 1.83337 1.58032 4.81814 1.58032 8.50004C1.58032 12.1819 4.56509 15.1667 8.24699 15.1667ZM12.0137 6.60004L7.31372 11.3L4.48039 8.46671L5.41372 7.53337L7.31372 9.43337L11.0804 5.66671L12.0137 6.60004Z"
            fill="#87D20D"
        />
    </svg>
);
