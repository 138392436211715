import { FC, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface Props extends PropsWithChildren {
    header: string;
}

export const SelectItem: FC<Props> = ({ header, children }) => {
    const defaultClassName =
        "bg-white rounded-xl border border-baltic-sea border-opacity-10 p-8 h-fit";

    return (
        <section className={twMerge(defaultClassName, "p-4 mb-2")}>
            <h3 className="text-baltic-sea font-semibold mb-4">{header}</h3>
            {children}
        </section>
    );
};
