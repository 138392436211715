import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Slide } from "react-awesome-reveal";
import { SectionId } from "enums/index";
import { Collapse } from "./common/collapse.component";

interface FaqDataContent {
    title: string;
    content: JSX.Element;
}

export const FAQ: FC = () => {
    const { t } = useTranslation();
    const textStyle = "text-sm";

    const renderQuestion = ({ title, content }: FaqDataContent) => (
        <li key={title} className="border-b border-black border-opacity-15 px-8 py-6 lg:px-4">
            <Collapse title={title} content={content} />
        </li>
    );

    const faqData: FaqDataContent[] = [
        {
            title: "faq.business",
            content: <p className={textStyle}>{t("faq.thickness")}</p>
        },
        {
            title: "faq.purchasing",
            content: <p className={textStyle}>{t("faq.after")}</p>
        },
        {
            title: "faq.pick",
            content: <p className={textStyle}>{t("faq.traditionally")}</p>
        }
    ];

    return (
        <section
            id={SectionId.FAQ}
            className="w-full px-9 pb-20 mt-2 flex flex-col items-center justify-center max-w-360 lg:px-4">
            <Slide triggerOnce>
                <h1 className="text-manatee text-5xl font-normal text-center mb-2 leading-24 lg:text-4xl">
                    <Trans
                        i18nKey={"faq.header"}
                        components={{
                            custom: <span className="text-baltic-sea font-semibold" />
                        }}
                    />
                </h1>
                <ul className="w-4/5 lg:w-full">{faqData.map(renderQuestion)}</ul>
            </Slide>
        </section>
    );
};
