import {
    BenefitsItemProps,
    CardTypeItemProps,
    HowItWorksItemProps,
    ItemQuantity,
    ManageAccountButtonsProps,
    NavigationLinkProps,
    PrivacyProps
} from "types/index";
import { SectionId } from "enums/index";
import { ButtonType } from "enums/index";
import { PRIVACY, REFUND, SHIPPING, TERMS } from "router/constants";

import CardTypeIcon1 from "assets/images/card-type1.png";
import CardTypeIcon2 from "assets/images/card-type2.png";
import CardTypeIcon3 from "assets/images/card-type3.png";

export const path = window.location.hostname;

export const brandName = process.env.REACT_APP_WEBSITE_TITLE;

export const supportLink = `support@${window.location.hostname}`;

export const phone = "+1 (833) 3338103";

export const originLink = window.location.origin;

export const manageAccountButtons: ManageAccountButtonsProps[] = [
    {
        name: "navigation.card",
        path: SectionId.CARD_TYPE,
        type: ButtonType.BLACK
    }
];
export const navigationLinkContent: NavigationLinkProps[] = [
    {
        name: "navigation.home",
        id: SectionId.HOME_PAGE
    },
    {
        name: "navigation.how",
        id: SectionId.HOW_IT_WORKS
    },
    {
        name: "navigation.products",
        id: SectionId.CARD_TYPE
    },
    {
        name: "navigation.why",
        id: SectionId.BENEFITS
    },
    {
        name: "navigation.faq",
        id: SectionId.FAQ
    }
];

export const benefitsArray: BenefitsItemProps[] = [
    {
        header: "benefits.result",
        text: "benefits.works"
    },
    {
        header: "benefits.support",
        text: "benefits.full"
    },
    {
        header: "benefits.money",
        text: "benefits.time"
    }
];

export const cardTypeArray: CardTypeItemProps[] = [
    {
        image: CardTypeIcon1,
        header: "cardType.standard",
        size: '3.5" x 2.0"',
        btnText: "cardType.standardCard",
        path: "/?packId=1"
    },
    {
        image: CardTypeIcon2,
        header: "cardType.businessCard",
        size: '2.56" x 2.56"',
        btnText: "cardType.boxSize",
        path: "/?packId=2"
    },
    {
        image: CardTypeIcon3,
        header: "cardType.specialSize",
        size: '3.3" x 2.16"',
        btnText: "cardType.getSpecial",
        path: "/?packId=3"
    }
];

export const howItWorksArray: HowItWorksItemProps[] = [
    {
        header: "howItWorks.straightforward",
        text: "howItWorks.business"
    },
    {
        header: "howItWorks.important",
        text: "howItWorks.small"
    },
    {
        header: "howItWorks.modern",
        text: "howItWorks.professional"
    }
];

export const privacyContent: PrivacyProps[] = [
    {
        name: "privacy.privacyPolicy",
        id: PRIVACY
    },
    {
        name: "privacy.terms",
        id: TERMS
    },
    {
        name: "privacy.refund",
        id: REFUND
    },
    {
        name: "privacy.shipping",
        id: SHIPPING
    }
];

export const quantityArray: ItemQuantity[] = [
    {
        id: 100,
        price: 24.99
    },
    {
        id: 250,
        price: 51.99
    },
    {
        id: 400,
        price: 89.99
    },
    {
        id: 700,
        price: 159.99
    }
];
